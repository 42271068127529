import { Component, Inject, Vue } from "vue-property-decorator";
import Utils from "@/vue/infrastructure/utils/helpers";
import vuescroll from "vuescroll";
import { CONTAINER_TYPES } from "@/vue/infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Swal from "sweetalert2";
import DfStore from "@/vue/domain/store/df-store";

const DfHeaderMobileComponent = () => Utils.externalComponent2("df-header-mobile");
const DfStoreCardComponent = () => Utils.externalComponent2("df-store-card");

@Component({
  components: {
    DfLoadingMask: () => import(/* webpackChunkName: 'df-loading-mask' */ "@/vue/components/df-loading-mask/df-loading-mask.vue"),
    DfFooter: () => Utils.externalComponent2("df-footer"),
    DfHeaderMobile: DfHeaderMobileComponent,
    DfStoreCard: DfStoreCardComponent,
    vuescroll,
  },
  filters: {
    cardStoreInfo(currentStore: DfStore): string {
      let storeInfo = "";
      if (currentStore != null) {
        storeInfo = storeInfo.concat(currentStore.postalCode != null ? ` ${currentStore.postalCode}` : "");
        storeInfo = storeInfo.concat(currentStore.city != null ? ` ${currentStore.city}` : "");
        storeInfo = storeInfo.concat(currentStore.province != null ? ` (${currentStore.province.code})` : "");
      }
      return storeInfo.trim();
    },
  },
})
export default class DfPagePasswordForgottenComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  private newPassword: string = null;
  private confirmPassword: string = null;
  private formError: any = {};
  private working = false;
  private newPasswordFieldType: "password" | "text" = "password";
  private newPasswordObfuscated = true;
  private newPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";
  private confirmPasswordFieldType: "password" | "text" = "password";
  private confirmPasswordObfuscated = true;
  private confirmPasswordObfuscatedIcon: "fa-eye" | "fa-eye-slash" = "fa-eye";

  get currentStore(): DfStore {
    return this.$store.getters.currentStore;
  }

  private resetPassword() {
    this.working = true;
    this.formError = [];
    this.service
      .resetPassword({
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
        resetPasswordToken: this.$route.params.resetPasswordToken,
      })
      .then((response: any) => {
        this.working = false;
        Swal.fire({
          title: this.$t("df-page-password-forgotten.modal.recovery-password.title").toString(),
          text: this.$t("df-page-password-forgotten.modal.recovery-password.body").toString(),
          icon: "info",
          confirmButtonColor: "#3085d6",
          confirmButtonText: this.$t("df-page-password-forgotten.modal.recovery-password.ok").toString(),
        }).then((result) => {
          if (result.value) {
            this.$router.push({ name: "home-store", params: { storeAlias: null } }).catch((err) => err);
          }
        });
      })
      .catch((error: any) => {
        this.working = false;
        if (error.response.data.errors) {
          error.response.data.errors.map((errorData: any) => {
            this.formError[errorData.field] = `${errorData.objectName}-${errorData.code}`;
          });
        } else {
          Swal.fire({
            title: this.$t("df-page-password-forgotten.modal.recovery-password.error.title").toString(),
            text: this.$t("df-page-password-forgotten.modal.recovery-password.error.body").toString(),
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: this.$t("df-page-password-forgotten.modal.recovery-password.error.ok").toString(),
          }).then((result) => {
            if (result.value) {
              this.$router.push({ name: "home-store", params: { storeAlias: null } }).catch((err) => err);
            }
          });
        }
      });
  }

  private goToStorePage(store: DfStore) {
    this.$router.push({ name: "store", params: { storeAlias: store.alias } }).catch((err) => err);
  }

  private openMap() {
    window.open(`${Utils.GOOGLE_MAP_URL}${Utils.storeGoogleMapsCoordinates(this.currentStore)}`, "_blank");
  }

  private changeNewPasswordFieldType() {
    this.newPasswordObfuscated = !this.newPasswordObfuscated;
    this.newPasswordObfuscatedIcon = this.newPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.newPasswordFieldType = this.newPasswordFieldType == "text" ? "password" : "text";
  }

  private changeConfirmPasswordFieldType() {
    this.confirmPasswordObfuscated = !this.confirmPasswordObfuscated;
    this.confirmPasswordObfuscatedIcon = this.confirmPasswordObfuscatedIcon == "fa-eye" ? "fa-eye-slash" : "fa-eye";
    this.confirmPasswordFieldType = this.confirmPasswordFieldType == "text" ? "password" : "text";
  }
}
